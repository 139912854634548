<template>
  <b-card-code>
    <!-- <p>{{organization}}organisation</p> -->
    <b-card-code class="bg-light-secondary mb-1" title="Filters">
      <b-form inline style="display: inline-block; width: 100%">
        <div class="d-flex flex-row align-items-center">
          <div class="row w-100">
            <div class="col-12 w-100">
              <div class="row">
                <div
                  v-if="is_staff"
                  class="col d-flex align-items-center justify-content-start"
                >
                  <v-select
                    v-model="organization"
                    label="text"
                    placeholder="--Select Organization--"
                    :options="organizations"
                    @input="loadOrgUsers"
                    autocomplete
                    style="width: 100%"
                    :reduce="(organization) => organization.value"
                  />
                </div>
                <div
                  class="col d-flex align-items-center justify-content-start"
                >
                  <!-- @input="searchFn" -->
                  <v-select
                    style="width: 100%"
                    v-model="user"
                    label="text"
                    placeholder="--Select User--"
                    :options="users"
                    autocomplete
                    :reduce="(user) => user.value"
                    @input="loadOrg"
                  />
                  <!-- {{user}} -->
                </div>
                <div
                  class="col d-flex align-items-center justify-content-start"
                >
                  <b-form-input
                    class=""
                    style="width: 100%"
                    id="name"
                    v-model="name_filter"
                    name="name_filter"
                    placeholder="Search with message"
                  />
                </div>
              </div>
            </div>
            <div class="w-100 col-12 mt-1">
              <div class="row">
                <div
                  v-if="organization == 'system'"
                  class="col d-flex align-items-center"
                >
                  <v-select
                    v-model="system_organization"
                    label="text"
                    placeholder="--Select Organization--"
                    :options="syorganizations"
                    autocomplete
                    style="width: 100%"
                    :reduce="(system_organization) => system_organization.value"
                  />
                </div>
                <div
                  class="col d-flex align-items-center justify-content-start"
                >
                  <flat-pickr
                    placeholder="Start Date"
                    style="width: 100%"
                    v-model="fromDate"
                    class="form-control mr-1 bg-background"
                  />
                  <span>to</span>

                  <flat-pickr
                    placeholder="End Date"
                    style="width: 100%"
                    v-model="endDate"
                    class="form-control ml-1 bg-background"
                    :disabled="fromDate ? false : true"
                  />
                </div>

                <div class="col-3 d-flex justify-content-end">
                  <b-button
                    variant="primary"
                    @click="searchFn"
                    style="w-100"
                    :disabled="user == null"
                  >
                    <span v-if="isloading"
                      ><b-spinner type="border" small></b-spinner> Please
                      wait</span
                    >
                    <span v-else
                      ><feather-icon
                        icon="SearchIcon"
                        class="mr-50"
                      />Search</span
                    >
                  </b-button>
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-else class="w-100">
            <b-form-input
              class="w-75 mr-1"
              id="name"
              v-model="name_filter"
              name="name_filter"
              placeholder="Search with message"
            />
            <b-button variant="primary" @click="searchFn">
              <span v-if="isloading"
                ><b-spinner type="border" small></b-spinner> Please wait</span
              >
              <span v-else
                ><feather-icon icon="SearchIcon" class="mr-50" />Search</span
              >
            </b-button>
          </div> -->
        </div>
      </b-form>
    </b-card-code>

    <div v-if="items.length">
      <div class="d-flex justify-content-center mb-1" v-if="loading">
        <b-spinner class="float-right" label="Floated Right" />
      </div>
      <b-table
        responsive
        id="LogTable"
        :fields="fields"
        :items="items"
        :per-page="perPage"
        ref="table"
        v-if="!loading"
        hover
      >
        <template #cell(index)="data">
          {{ data.index + 1 + perPage * (currentPage - 1) }}
        </template>
        <template #cell(log_message)="data">
          <p v-html="data.item.log_message"></p>
        </template>
        <template #cell(asset_name)="data">
          <router-link :to="'/assets/scan/' + data.item.asset_id">{{
            data.item.asset_name
          }}</router-link>
        </template>
        <template #cell(activity_time)="data">
          {{ data.item.activity_time | formatdate }}
        </template>
        <!-- <template #cell(activity_time)="data">
        <span>{{ new Date(data.item.activity_time).toLocaleString() }}</span>
      </template> -->
      </b-table>

      <b-pagination-nav
        v-model="currentPage"
        :numberOfPages="total"
        :total-rows="rows"
        :link-gen="linkGen"
        :per-page="perPage"
        :align="pagination_pos"
        aria-controls="LogTable"
        use-router
        v-if="!loading"
      />
    </div>
    <div v-else>
      <div
        class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
      >
        <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
        <h3 class="font-weight-bolder">No Data Found</h3>
        <p>Choose filters and click the Search button to view logs.</p>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import moment from "moment";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import {
  BPagination,
  BPaginationNav,
  BButton,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BFormSelect,
    BSpinner,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      pagination_pos: "center",
      is_staff: this.$store.state.app.user.is_staff,
      organization: this.$store.state.app.org_id,
      // organizations: this.$store.state.app.organizations,
      // organization: null,
      organizations: [],
      syorganizations: [],
      system_organization: null,
      user: null,
      users: [{ value: null, text: "--Select User--" }],
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      is_search_on: false,
      loading: false,
      fields: [
        { key: "index", label: "#" },
        { key: "log_message", label: "Log Message" },
        // { key: "user_email", label: "User Name" },
        { key: "activity_on", label: "Activity on" },
        { key: "asset_name", label: "Asset Name" },
        // { key: "ref_id", label: "Ref ID" },
        { key: "activity_time", label: "Activity Time" },
      ],
      name_filter: "",
      isloading: false,
      fromDate: null,
      endDate: null,
    };
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.searchFn(false);
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },

  created: function () {
    this.loadOrg();
    this.loadOrgUsers();
    // this.searchFn();
  },

  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    loadOrg: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      this.loading = true;
      // this.$store.state.app.user.groups[0].name === "Admin"
      if (this.$store.state.app.user.id == 1) {
        this.organizations = [
          { value: null, text: "--Select Organization--" },
          { value: "system", text: "Secusy System" },
        ];
      } else {
        this.organizations = [{ value: null, text: "--Select Organization--" }];
      }
      this.syorganizations = [{ value: null, text: "--Select Organization--" }];

      if (this.is_staff) {
        this.loading = true;
        let url =
          process.env.VUE_APP_BASEURL + "organization/organization/get-all-org";

        if (this.organization == "system" && this.user != null) {
          url = url + "?user_id=" + this.user;
        }
        // Load oragnization drop down
        const o_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: url,
        };

        var self = this;
        this.$http(o_options).then((res) => {
          // console.log(res.data)
          res.data.map(function (value, key) {
            let org = {
              // value: res.data[key].org_id,
              value: res.data[key].sso_ref_id,
              text: res.data[key].org_name,
            };
            // console.log(res.data[key].profile.organization)
            self.organizations.push(org);
            self.syorganizations.push(org);
          });
          this.loading = false;
        });
      } else {
        // this.organization = this.$store.state.app.user.profile.organization;
        this.organization = this.$store.state.app.org_id;
        // this.searchFn();
      }
    },
    loadOrgUsers: function () {
      if (this.organization === null || this.organization === "system") {
        this.user = null;
      }
      this.user = null;
      this.users = [{ value: null, text: "--Select User--" }];
      this.loading = true;
      // Load User drop down
      let url =
        process.env.VUE_APP_BASEURL + "user/users/get-all-users/?query=true";

      if (this.organization != null) {
        if (this.organization == "system") {
          url = url + "&role_type=" + 0;
        } else {
          url = url + "&q_org_id=" + this.organization;
        }
      }
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;

      this.$http(o_options).then((res) => {
        res.data.map(function (value, key) {
          let user = {
            value: res.data[key].sso_ref_id,
            text: res.data[key].first_name,
          };
          self.users.push(user);
        });
        this.loading = false;
      });
    },
    searchFn: function (reset = true) {
      this.isloading = true;
      this.is_search_on = false;
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "logs/v1/logs/user-logs?query=true" +
        "&page=" +
        this.currentPage;

      if (this.organization == "system" && this.system_organization != null) {
        this.filter.push({ system_organization: this.system_organization });
        url = url + "&org_id=" + this.system_organization;
      }
      // if (this.organization != null && this.organization != "system") {
      //   this.filter.push({ organization: this.organization });
      //   url = url + "&org_id=" + this.organization;
      // }

      if (this.user != null) {
        this.filter.push({ user: this.user });
        url = url + "&user_id=" + this.user;
      }
      if (this.name_filter != null && this.name_filter != "") {
        this.filter.push({ name_filter: this.name_filter });
        url = url + "&log_msg=" + this.name_filter;
      }
      if (this.fromDate != null && this.endDate == null) {
        url = url + "&from_date=" + this.fromDate;
        this.filter.push({ from_date: this.fromDate });
      }
      if (this.fromDate != null && this.endDate != null) {
        url = url + "&from_date=" + this.fromDate + "&end_date=" + this.endDate;
        this.filter.push({ from_date: this.fromDate, end_date: this.endDate });
      }

      if (this.filter.length) this.is_search_on = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
        this.isloading = false;
      });
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
::v-deep .v-text-field--box .v-input__slot,
.v-text-field--outline .v-input__slot {
  max-height: 300px;
  overflow-y: auto;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.bg-background {
  background: #fff !important;
}
[dir] .dark-layout .bg-background {
  background: #283046 !important;
  border-color: #404656;
}
</style>